/* Navbar.css */

/* General navbar layout */
.navbar {
  background-color: #2c3e50;
  padding: 15px;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navbar ul li {
  margin-right: 20px;
}

.navbar ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
}

.navbar ul li a:hover {
  text-decoration: underline;
}
