/* File: src/Footer.css */
footer {
  background-color: hsl(231, 78%, 56%);
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-icons {
  margin-top: 10px;
}

.social-media-icons a {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-media-icons a:hover {
  color: #f7c08a; /* Hover color for social media icons */
}
