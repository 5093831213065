/* General body styling */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

/* Header styling */
.site-header {
  background-color: #2c3e50;
  padding: 20px;
  text-align: center;
}

.site-title {
  font-size: 2.5rem;
  color: #fff;
  margin: 0;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 40px;
  max-width: 1200px;
  margin: 40px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  height: 500px;
}

/* Image container */
.image-container {
  flex: 1;
  margin-right: 40px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Form container */
.form-container {
  flex: 1;
  background-color: #2c3e50;
  padding: 40px;
  border-radius: 10px;
  color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Data-Donation Wrapper */
.data-donation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  max-width: 1200px;
  margin: 40px auto;
}

/* Data Visualization */
.data-visualization {
  flex: 1;
  text-align: center;
  margin-right: 40px;
}

.data-visualization h3 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Donation Section Styling */
.donation-section {
  flex: 1;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.donation-message h3 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 20px;
}

.donation-message p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
}

.donate-button {
  background-color: #ff7f50;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.donate-button:hover {
  background-color: #ff5722;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .image-container, .form-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .form-container {
    width: 100%;
  }

  /* Data Donation Section for Mobile */
  .data-donation-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .data-visualization, .donation-section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .data-visualization h3, .donation-message h3 {
    font-size: 1.5rem;
  }

  .donation-message p {
    font-size: 0.9rem;
  }

  .donate-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
/* Footer styling */
footer {
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  text-align: center;
}
