/* About.css */

.about-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #f5f5f5; /* Light background to keep text readable */
  color: #333; /* Text color for readability */
}

.profile-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 20px auto;
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a nice shadow for a modern look */
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.6;
  font-size: 1.5rem;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.about-content h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
}

.about-content p {
  margin-bottom: 20px;
}
